@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.root {
  display: block;
  margin: -40px -24px 10px -70px;
  padding: 10px 46px 0 107px;
  background-color: $wild-sand;

  @include media-tiny {
    padding: 0 0 0 7px;
    margin-left: 0;
    margin-right: -12px;
  }
}

.header {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.items {
  display: flex;
  overflow: auto;
}
