@import "../../variables.scss";
@import "../../mixins.scss";

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;

  :first-child {
    margin-bottom: 0;
  }

  @include media-tiny {
    :first-child {
      flex: 1 1 0%;
    }
  }
}

.add {
  margin-left: 6px;

  &__md {
    display: none;
    @include media-min-max($screen-small, $screen-medium - 1) {
      display: unset;
    }
  }

  svg {
    @include media-small {
      display: none;
    }
  }
}