@import "../../../../variables.scss";

.search {
  flex: 1;
  margin-right: 14px;
  max-width: 350px;
}

.provider {
  flex: 0.3;
  min-width: 142px;
  max-width: 200px;
  background-color: $white;
}