@import "../../../../variables.scss";

.mobile-input {
  border: 1px solid $gemini-gray;
  border-radius: 25px;
  outline: none;
  padding: 9px 20px;
  width: 100%;
  font: inherit;
  color: inherit;
  font-size: 16px;

  &::placeholder {
    color: $pesto-gray;
  }

  &:disabled {
    color: $pesto-gray;
    background-color: $white;
  }
}

.input {
  border: none;
  outline: none;
  padding: 11px 20px;
  width: 100%;
  height: 100%;
  font: inherit;
  color: inherit;

  &::placeholder {
    color: #a8a8a8;
  }
}

.input-container {
  position: relative;
  display: inline-block;
  border: $input-border;
  border-radius: $input-border-radius;
  height: 41px;
  overflow: hidden;
  background-color: $bluish-grey;
}

.icon {
  position: absolute;
  right: 12px;
  top: 50%;
  display: inline-flex;
}