.root {
  .retention-mode {
    width: 260px;
  }
  
  .retention-param-label {
    margin-left: 0;
  }

  .retention-param-value {
    margin-left: 30px;
    flex-direction: column;
  }

  .input {
    input {
      width: 200px;
    }
  }
}

.retention-param {
  &--disabled {
    opacity: 0.6;
  }
}

.revisions-retention {
  margin-top: 35px;
  margin-bottom: 40px;
}