.root {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  button {
    height: 38px;
  }

  .ok-btn-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .countdown {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
