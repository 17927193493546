@import "../../../../variables.scss";

.panel {
  width: 950px;
}

.border {
  padding: 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  border: 1px solid $pesto-gray;
  background-color: $alabaster;
}

.col {
  &--storage-node-provider {
    width: 200px;
  }
  &--storage-node-bucket {
    white-space: nowrap;
    min-width: 300px;
  }
}
