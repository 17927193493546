.col {
    &--status {
        text-align: center;
        width: 75px;
    }
}

.name-container {
    display: flex;

    .name-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .name-postfix {
        min-width: 50px;
        margin-left: 3px;
    }
}