@import "../../../../variables.scss";

.content {
  width: 400px;

  .reset-link {
    margin-top: 15px;
  }

  .sign-in-error-message {
    color: $dark-orange;
    font-size: 12px;
    margin-top: 6px;
  }
}
