@import "../../../../mixins.scss";

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;

  :first-child {
    margin-bottom: 0;
  }

  @include media-tiny {
    :first-child {
      flex: 1 1 0%;
    }
  }
}

.action-buttons {
  button, a {
    margin-left: 6px;
  }
}
