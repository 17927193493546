@import "../../../../variables.scss";

.mobile-input {
  border: 1px solid $gemini-gray;
  border-radius: 25px;
  outline: none;
  padding: 9px 20px;
  width: 100%;
  font: inherit;
  color: inherit;
  font-size: 16px;

  &::placeholder {
    color: $pesto-gray;
  }

  &:disabled {
    color: $pesto-gray;
    background-color: $white;
  }
}

.root {
  display: flex;
  align-items: center;
}

.minute,
.hour {
  width: 57px;
  input {
    text-align: center;
    width: 100%;
  }
}

.time-inputs {
  display: flex;
  align-items: center;
}

.time-splitter {
  font-size: 20px;
  padding: 0 6px;
}

.am-pm {
  margin-left: 15px;
  display: flex;
  flex-wrap: nowrap;
}

.pm-button {
  margin-left: 4px;
}