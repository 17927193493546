@import "../../../../mixins.scss";

.root {
  margin-top: 40px;
}

.description {  
  margin: 9px 0 26px 0;
}

.col-executed-on,
.col-restored-to {
  width: 150px;
}