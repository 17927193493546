.container {
  border: solid black;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, #000410, #171f38);
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.paper {
  background-color: #ffffff;
  width: 400px;
  padding: 15px 25px;

  .title {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
  }

  .password-validation-rules-desc {
    margin: 15px 0 20px 35px;
    ul {
      margin-top: 10px;
    }
    li {
      list-style: disc;
    }
  }
}
