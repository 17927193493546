@import "../../../../variables.scss";

.root {
  position: relative;
  display: flex;
  flex: 1;

  .button {
    position: absolute;
    right: 6px;
    top: 8px;
    color: $gemini-gray;
  }

  .input {
    padding-right: 40px;
  }
}