.root {
  display: grid;
  grid-template-columns: 90px auto;
}

.email {
  grid-column: 2;
  grid-row: 1;
}

.name {
  grid-column: 2;
}