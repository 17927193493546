@import "../../../../variables.scss";

.content {
  width: 400px;

  .reset-link {
    margin-top: 15px;
  }

  .password-validation-rules-desc {
    margin: 15px 0 20px 35px;
    ul {
      margin-top: 10px;
    }
    li {
      list-style: disc;
    }
  }
  .confirmation-email-message {
    margin-bottom: 20px;
  }

  .btn-group {
    margin-top: 50px;
  }
}
