@import "../../../variables.scss";

.root {
  width: 100vw;
  height: 100px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $bluish-grey;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;

  button {
    margin-left: 30px;
  }
}

.text {
  font-size: 24px;
  color: $white;
  margin-right: 20px;
}

