.panel {
  width: 950px;

  .field {
    margin-top: 30px;

    & > div {
      margin-left: 0;
      margin-bottom: 15px;
    }
  }
}
