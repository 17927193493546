@import "../../../../mixins.scss";

@mixin compact-view {
  .text {
    display: none;
  }
  .icon {
    margin: 0;
  }
}

.root {
  &--compact-on-small {
    display: flex;
    @include media-small-or-less {
      @include compact-view;
    }
  }
  &--compact-on-medium {
    @include media-medium-or-less {
      @include compact-view;
    }
  }
}

.icon {
  margin-left: -5px;
  margin-right: 5px;
}
