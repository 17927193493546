@import "../../../variables.scss";
@import "../../../mixins.scss";

.root {
  display: flex;
  margin: 0 20px 14px 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $screen-medium) {
    min-width: 161px;
  }
  @include media-small {
    min-width: 140px;
  }
}
