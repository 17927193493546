@import "../../../variables.scss";
@import "../../../mixins.scss";

@mixin full-view {
  .title,
  .footer,
  .open-button,
  .close-button {
    display: none;
  }

  .side-panel {
    position: static;
    background: none;
    overflow: visible;
  }

  .panel {
    position: static !important;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

@mixin compact-view {
  .main-export-button {
    display: none;
  }

  .side-panel {
    position: fixed;
    left: 0;
    right: 0;
  }

  .panel {
    padding: 37px 16px 46px 36px;
    background-color: $white;
    left: 0;
    right: 0;
  }

  .content {
    & > div {
      width: 177px;
      &:not(:first-child) {
        margin-top: 42px;
      }
    }
  }
}

.root {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;

  @include media-tiny {
    margin-bottom: 29px;
  }

  &--compact-on-small {
    @include media-min-small {
      @include full-view;
    }

    @include media-tiny {
      @include compact-view;
    }
  }

  &--compact-on-medium {  
    @include media-min-medium {
      @include full-view;
    }

    @include media-small-or-less {
      @include compact-view;
    }   
  }
  
  .main-export-button-item {
    min-width: auto;
  }

  .side-panel {
    z-index: $left-panel-z-index - 1;
  }
}

.title {
  font-family: $raleway;
  font-size: 26px;
  color: $dark-blue-grey;
  font-weight: bold;
  margin-bottom: 29px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 38px;

  button {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.open-button {
  color: $bluish-grey;
  svg {
    font-size: 40px;
  }
}

.close-button {
  position: absolute;
  top: 28px;
  right: 16px;
  svg {
    font-size: 25px;
    color: $gemini-gray;
  }
}
