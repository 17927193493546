// fonts
$raleway: "Raleway";
$open-sans: "Open Sans";

//colors palette
$white: #fff;
$black: #292929;
$rich-black: #020613;
$very-light-gray: #dbdbdb;
$dark-orange: #cb4100;
$transparent-dark-orange: rgba(203, 65, 0, 0.1);
$bluish-grey: #6c969d;
$smalt-blue: #567f86;
$dark-blue-grey: #171f38;
$light-blue-grey: #b8d5d9;
$red: #cb0000;
$grassy-green: #289f04;
$gemini-gray: #707070;
$gun-powder: #4d4f5c;
$dark-gun-powder: #43425d;
$snowflake: #fcfcfc;
$pesto-gray: #a8a8a8;
$wild-sand: #f4f4f4;
$tangerine: #f58400;
$alabaster: #fafafa;
$mystic: #edf0f5;
$yellow: #efaf00;
$alabaster: #f8f8f8;
$alto: #D8D8D8;
$bridal-heath: #fff9f2;
$gallery: #ebebeb;

//input borders
$input-border-color: $gemini-gray;
$input-border: solid 1px $input-border-color;
$input-border-radius: 20px;

//z-index
$local-loader-z-index: 1;
$loader-z-index: 1310;
$left-panel-z-index: 10;

//screen size
$screen-large: 1440px;
$screen-medium: 1024px;
$screen-small: 768px;
$screen-tiny: 375px;

//elements size
$top-nav-height: 80px;
$top-nav-height-tiny: 60px;

$details-form-elements-std-margin-left: 27px;
