@import "../../../../variables.scss";

.root {
  &:first-child {
    margin-left: 0;
  }
  display: flex;
  margin: 7px 0 15px 35px;
  padding: "0px 8px";
  background-color: $pesto-gray;
  line-height: 1.36;
  font-size: 14px;
  height: 32px;
  color: $white;
  border-radius: 16px;
  width: "auto";
}