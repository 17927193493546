@import "../../../mixins.scss";

.root {
  .panel {
    @include media-min-small {
      width: 700px;
    }
  }
}

.form-content {
  display: flex;
  flex-wrap: wrap;
}

.restore-date-field {
  max-width: 250px;
  min-width: 220px;
}