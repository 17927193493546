@import "../../../../mixins.scss";

.root {
  display: inline-block;
  border: 1px solid $gemini-gray;
  border-radius: 20px;
  overflow: hidden;

  &--buttons {
    .input {
      width: 50px;
      text-align: center;
      padding: 12px 5px 11px 5px;
    }
  }
}

.input {
  padding: 12px 20px 11px 20px;
  border: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

.increase-button,
.decrease-button {
  height: 37px;
  color: $gemini-gray;
}

.increase-button {
  padding-right: 12px;
  padding-left: 2px;
}

.decrease-button {
  padding-left: 12px;
  padding-right: 2px;
}