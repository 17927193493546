@import "../../../../variables.scss";

.content {
  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 15px;
}

.provider-properties {
  display: flex;
  flex-direction: column;
}

.provider-fields-container {
  display: flex;
}

.provider-fields {
  width: 380px;
}

.import-gcs-config-section {
  display: flex;
  flex-direction: row;

  input {
    flex-grow: 4;
  }
  button {
    flex-grow: 1;
    margin-left: 5px;
  }
}

.test-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 50px;

  * {
    margin-bottom: 10px;
  }
}

.field-provider {
  width: 200px;
  margin-bottom: 14px;
}
