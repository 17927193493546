@import "../../../../variables.scss";


.input {
  border: 1px solid $gemini-gray;
  border-radius: 25px;
  outline: none;
  padding: 9px 20px;
  width: 100%;
  font: inherit;
  color: inherit;
  font-size: 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $pesto-gray;
  }

  &:disabled {
    color: $pesto-gray;
    background-color: $white;
  }

  &--error {
    border-color: $dark-orange;
  }
}