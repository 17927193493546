.container {
  border: solid black;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, #000410, #171f38);
}

.loginwrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.paper {
  background-color: #ffffff;
  width: 400px;
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginButton {
  margin-top: 17px;
  margin-left: 8px;
}
