@import "../../../../../variables.scss";

.content {
  padding-bottom: 20px;
}

.critical {
  border: 2px solid $dark-orange;
}

.button-ok {
  margin: auto;
  padding-left: 35px;
  padding-right: 35px;
}