@import "../../../../../variables.scss";
@import "../../../../../mixins.scss";

.steps-indicator {
  display: flex;
  flex-direction: column;

  &--horizontal {
    height: 100%;
    width: 100%;
    max-width: 400px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .step {
      margin-right: 0;
      margin-bottom: -18px;
      padding: 0;

      &--active {
        margin-bottom: -23px;
      }

      &--done {
        margin-bottom: 0px;
      }
    }

    .name {
      display: none;
    }
  }
}

.step {
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 22px;
  padding: 18px 0 18px 33px;
  font-size: 16px;
  color: #e6e6e6;
  margin-bottom: 10px;

  &--active {
    font-weight: 600;
    margin-right: -17px;
    
    .ball {
      @include size(59px);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .num {
      color: $dark-orange;
      font-size: 24px;
    }
  }

  &--done {
    opacity: 0.5;
    margin-right: 10px;

    .ball {
      right: 10px;
    }
    .tick {
      display: block;
    }
    .num {
      display: none;
    }
  }
}

.name {
  color: $very-light-gray;
  margin-right: 26px;
  margin-left: -10px;
}

@include media-medium {
  .name {
    display: none;
  }
}

.ball {
  @include size(44px);
  background: $white;
  color: $dark-blue-grey;
  border: 1px solid $dark-blue-grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  z-index: 1;
}

.tick {
  display: none;
  width: 6px;
  height: 14px;
  border: solid #171f38;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
